<script setup lang="ts">
import PageCaption from '@sub/components/PageCaption.vue';
import { hasCompanyAccessToPlatform } from '@utils/tariff';
import { Styles } from '@ui/styles';

const i18nHead = useLocaleHead({
})

useHead({
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs?.lang
  },
  link: [...(i18nHead.value.link || [])],
  meta: [...(i18nHead.value.meta || [])]
});

const store = useCompanyStore();

const route = useRoute();
const expired = computed(() => (route.name?.startsWith('booking-id') || route.name?.startsWith('orders-id')) && !hasCompanyAccessToPlatform(store.company));
</script>

<template lang="pug">
.layout-container.flex
  .layout.flex-center-center.col-16.w-100p
    .template.br-24.max-w-800.w-100p.h-100p.flex.col-0.overflow-hidden
      .expired.h-100p.max-w-420.m-auto.position-relative.flex-center-center.text-center.col-16(v-if="expired")
          .flex {{ $t('block.booking.disabled') }}
          .flex {{ $t('block.booking.write_admin') }}
          UiButton(label="block.booking.contact" :size="Styles.Sizes.Small" :to="`mailto:${store.company.meta?.email}`")
      template(v-else)
        slot
    PageCaption
</template>

<style scoped lang="scss">
.layout-container {
  min-height: max(600px, 100dvh);

  @screen md {
    //max-height: max(800px, 100dvh);
  }
}

.layout {
  --uno: p-b-16;
  @screen md {
    --uno: p-20;
  }
}

.template {
  //overflow: hidden;
  background: #FFFFFF;
  
  @screen md {
    box-shadow: 0 0 2px #0003;
  }
}
</style>

<style lang="scss">
body {
  @screen md {
    background: #F8F8F9;
  }
}
</style>